import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | VonderLite - твій шлях до професіоналізму
			</title>
			<meta name={"description"} content={"У VonderLite водіння - це більше, ніж просто навичка, це мистецтво, почуття, історія, яка розгортається з кожним кілометром. Кожна подорож має свій ритм, своє серцебиття. Через перехрестя жвавих вулиць, до спокою відкритих доріг, є розповідь, яка чекає, щоб бути написаною. "} />
			<meta property={"og:title"} content={"Головна | VonderLite - твій шлях до професіоналізму"} />
			<meta property={"og:description"} content={"У VonderLite водіння - це більше, ніж просто навичка, це мистецтво, почуття, історія, яка розгортається з кожним кілометром. Кожна подорож має свій ритм, своє серцебиття. Через перехрестя жвавих вулиць, до спокою відкритих доріг, є розповідь, яка чекає, щоб бути написаною. "} />
			<meta property={"og:image"} content={"https://vonderlite.com/photos/background.jpg"} />
			<link rel={"shortcut icon"} href={"https://vonderlite.com/photos/logo.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vonderlite.com/photos/logo.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vonderlite.com/photos/logo.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vonderlite.com/photos/logo.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vonderlite.com/photos/logo.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vonderlite.com/photos/logo.png"} />
		</Helmet>
		<Components.Header />
		<Section
			md-justify-content="center"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.1) 67.71%,rgba(4, 8, 12, 0.1) 100%),url(https://vonderlite.com/photos/background.jpg) center center/cover no-repeat"
			min-height="100vh"
			sm-padding="40px 0 40px 0"
			quarkly-title="Hero-24"
			padding="0px 0 0px 0"
			md-align-items="center"
		>
			<Override
				slot="SectionContent"
				max-width="none"
				width="100%"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				flex-wrap="wrap"
				justify-content="flex-end"
				align-items="flex-end"
			/>
			<Box
				display="flex"
				align-items="flex-start"
				justify-content="center"
				padding="36px 48px 56px 48px"
				background="linear-gradient(120.99deg, #04080C 0%, rgba(25, 30, 34, 0.7) 100%)"
				flex-direction="column"
				width="30%"
				lg-width="50%"
				md-width="60%"
				sm-width="90%"
			>
				<Text
					text-transform="uppercase"
					letter-spacing="2px"
					margin="0px 0px 13px 0px"
					color="--lightD2"
					font="--base"
					lg-width="100%"
					lg-text-align="center"
				>
					Курс Водійської практики
				</Text>
				<Text
					lg-width="100%"
					lg-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="0px 0px 17px 0px"
					color="--light"
					font="--headline2"
				>
					VonderLite
				</Text>
				<Text
					color="--lightD2"
					font="--base"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					Виховання впевнених водіїв, один персоналізований урок за раз
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Представлення
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="70%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					Привіт і сердечні вітання, я Стас. У VonderLite я вважаю, що водіння - це більше, ніж просто навичка, це мистецтво, почуття, історія, яка розгортається з кожною милею. Кожна подорож має свій ритм, своє серцебиття. Через перехрестя жвавих вулиць, до спокою відкритих доріг, є розповідь, яка чекає, щоб бути написаною. Маючи понад десятирічний досвід, я бачив, як боязкі початківці перетворюються на впевнених дорожніх навігаторів. Мій підхід простий: об'єднати технічні аспекти водіння з емоційним зв'язком, який відчуваєш за кермом. Разом ми вивчимо не лише механіку, а й суть водіння, щоб ви були готові не лише до дороги, а й до подорожі.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Зосередженість один на один:
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						тільки ви, я і відкрита дорога. Кожен урок ретельно розроблений, щоб ви чітко засвоїли кожен елемент. Ваші запитання, ваші проблеми, ваші етапи - вони в центрі уваги VonderLite.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Сутінкові випробування:{" "}
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						З заходом сонця з'являється зовсім інший світ - м'яке світло фар, прохолодний вітерець і спокій. Відчуйте цю магію з перших вуст на фірмових вечірніх уроках Стаса, розроблених для того, щоб вселити впевненість та адаптивність.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Гнучкі шляхи:{" "}
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Життя непередбачуване, я розумію. Незалежно від того, чи жонглюєте ви роботою, навчанням або іншими зобов'язаннями, наші уроки будуть заплановані з урахуванням вашої зручності та комфорту.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Готові розпочати індивідуальний курс водіння?
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Давайте прокладемо шлях разом.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-indigo"
				>
					Контакти
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://vonderlite.com/photos/1.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});